@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.text-link {
  display: flex;
  flex-direction: row;

  .text-link-name {
    font-size: 14px;
    @include theme-aware('color', 'secondary-100');
    font-weight: 500;
  }

  &:hover {
    .text-link-name {
      @include theme-aware('color', 'secondary-80');
      transition: all 0.2s;
    }
  }

  &:focus-visible {
    @include theme-aware('box-shadow', 'outline-box-shadow');
  }
}
