@import '../../../styles/mixins.scss';
@import '../../../styles/themes.scss';

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 210px;
  transition: all 0.6;

  .typography {
    width: 90%;
    border-top: 1px solid;
    @include theme-aware('border-color', 'neutrals-70');
    font-size: 10px;
    margin: 0 auto;
    @include theme-aware('color', 'neutrals-70');
    justify-content: center;

    .text-link-name {
      font-size: 11px;
    }
  }

  &.compact {
    width: 80px;
    transition: all 0.5;

    .typography {
      width: 70px;

      .versioning {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar-footer {
    width: 100%;
    &.compact {
      display: none;
    }
  }
}
