@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.simple-card-container {
  @include theme-aware('background-color', 'neutrals-100');
  @include theme-aware('box-shadow', 'primary-box-shadow');
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  &.interactive {
    cursor: pointer;
  }

  &.outlined {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid;
    @include theme-aware('border-color', 'primary-100');
  }
}

@media only screen and (max-width: 768px) {
  .simple-card-container {
    padding: 10px;
  }
}
