@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.typography {
  font-family: 'Roboto';
  white-space: pre-line;

  &-h1 {
    @include theme-aware('color', 'neutrals-40');
    text-align: left;
    font-family: 'Public Sans';
    margin: 10px 0;
    font-size: clamp(24px, 1vw + 1rem, 32px);
    font-weight: 500;
    line-height: 38px;
    letter-spacing: normal;
  }

  &-h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'Public Sans';
    @include theme-aware('color', 'neutrals-40');
    text-align: left;
  }

  &-h3 {
    font-size: 22px;
    line-height: 30px;
    @include theme-aware('color', 'neutrals-40');
    text-align: center;
    font-weight: 400;
    margin: 3px 0;
  }

  &-h4 {
    font-size: 20px;
    @include theme-aware('color', 'neutrals-40');
    text-align: center;
    font-weight: 400;
  }

  &-h5 {
    font-size: 18px;
    @include theme-aware('color', 'neutrals-40');
    text-align: center;
    font-weight: 400;
  }

  &-subheading1 {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    @include theme-aware('color', 'neutrals-40');
    text-align: left;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  &-subheading2 {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    @include theme-aware('color', 'neutrals-40');
    text-align: left;
    margin: 5px 0;
    gap: 10px;
    align-items: center;
  }

  &-subheading3 {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    @include theme-aware('color', 'neutrals-40');
    text-align: left;
    margin: 5px 0;
    align-items: center;
  }

  &-body1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    @include theme-aware('color', 'neutrals-50');
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  &-body2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 12px;
  }

  &.warning {
    @include theme-aware('color', 'warning-color');
  }

  &.error {
    @include theme-aware('color', 'error-color');
  }

  &.success {
    @include theme-aware('color', 'success-color');
  }

  &.disabled {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 768px) {
  .typography {
    &-h1 {
      margin-bottom: 5px;
    }
    &-h2 {
      font-size: 24px;
    }
    &-subheading1 {
      margin: 5px 0;
    }
  }
}
