@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.logo-btn {
  margin: 0 auto;
  &.long {
    height: 30px;
    width: 77px;
  }
  &.short {
    height: 36px;
    width: 36px;
  }
  .short-logo {
    height: 36px;
    width: 36px;
    // width: 77px;
  }

  .long-logo {
    height: 30px;
    width: 77px;
  }

  &:focus-visible {
    @include theme-aware('box-shadow', 'outline-box-shadow');
  }
}
