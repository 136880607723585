@import '../../../styles/mixins.scss';
@import '../../../styles/themes.scss';

.creator-sidebar-container {
  padding: 5px 12px 5px 7px;
}

@media only screen and (max-width: 1024px) {
  .creator-sidebar-container {
    margin-top: 50px;
    padding: 5px 12px 5px 7px;
  }
}
