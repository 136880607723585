@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  @include theme-aware('color', 'secondary-90');
  min-width: 32px;
  font-family: 'Roboto';
  min-height: 32px;
  font-weight: 500;
  font-size: 14px;
  gap: 5px;
  svg {
    @include theme-aware('color', 'secondary-100');
  }

  &.error {
    @include theme-aware('color', 'error-color');
    svg {
      @include theme-aware('color', 'error-color');
    }
  }
  &:hover,
  &:active {
    @include theme-aware('color', 'secondary-80');
    transition: all 200ms;

    svg {
      transition: all 200ms;
      @include theme-aware('color', 'secondary-80');
    }
  }

  &.filled {
    padding: 8px;
    @include theme-aware('background-color', 'neutrals-80');
    border-radius: 50%;
    @include theme-aware('box-shadow', 'primary-box-shadow');

    &:hover,
    &:active {
      @include theme-aware('background-color', 'neutrals-70');
    }
  }

  &.outlined {
    min-width: 31px;
    min-height: 31px;
    border: 2px solid;
    @include theme-aware('border-color', 'secondary-100');
    border-radius: 50%;
    @include theme-aware('box-shadow', 'primary-box-shadow');

    &:hover,
    &:active {
      border: 2px solid;
      @include theme-aware('border-color', 'secondary-80');
    }
  }

  &:focus-visible {
    @include theme-aware('box-shadow', 'outline-box-shadow');
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
