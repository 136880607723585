@import '../../../styles/mixins.scss';
@import '../../../styles/themes.scss';

.sidebar-container {
  transition: width 0.3s;
  height: 100%;
  width: 80px;
  @include theme-aware('background-color', 'neutrals-100');
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 0;
  @include theme-aware('box-shadow', 'primary-box-shadow');
  flex-shrink: 0;
  gap: 20px;
  .sidebar-logo-expand {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .sidebar-expand-btn {
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;
    justify-content: flex-end;

    svg {
      width: 28px;
      height: 28px;
      transition: transform 0.2s;
    }
  }
  .sidebar-btns {
    padding: 5px;
    gap: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &.extended {
    width: 220px;
    transition: width 0.2s;

    .sidebar-expand-btn {
      svg {
        transform: rotateZ(180deg);
        transition: transform 0.2s;
      }
    }
  }
  .pwa-btn {
    justify-content: flex-start;
    padding: 0 5px;
    gap: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar-container {
    position: absolute;
    transition: height 0.3s;
    z-index: 10;
    padding: 5px 10px;
    height: 50px;
    width: 100%;
    gap: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .sidebar-logo-expand {
      display: flex;
      width: 100%;
      height: 50px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .logo-btn {
      margin: 0;
      padding: 0px 5px;
      height: 36px;
      width: 36px;
      img {
        height: 36px;
        width: 36px;
      }
    }
    .sidebar-btns {
      position: absolute;
      margin-top: -1000px;
      margin-left: -1000px;
      transition: margin 0.3s;
      width: 0;
    }
    .sidebar-expand-btn {
      padding: 0px;
      height: 36px;
      width: 36px;
      .btn {
        height: 32px;
        width: 32px;
        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
    &.extended {
      width: 100%;
      height: 100%;
      transition: height 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      .sidebar-btns {
        margin-top: 100px;
        margin-left: 0px;
        transition: margin-top 0.3s;
        width: 100%;
      }
    }
  }
}
