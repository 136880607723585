@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.styled-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @include theme-aware('background-color', 'neutrals-90');
  @include theme-aware('color', 'neutrals-30');
  line-height: 30px;
  .qr-code {
    display: grid;
    grid-template-columns: repeat(5, 10px);
    grid-gap: 6px;
  }

  .qr-code div {
    width: 10px;
    height: 10px;
    @include theme-aware('background-color', 'secondary-130');
    animation: pulse 1.5s infinite ease-in-out;
  }

  /* Animate specific squares to simulate a QR code pattern */
  .qr-code div:nth-child(1),
  .qr-code div:nth-child(5),
  .qr-code div:nth-child(21),
  .qr-code div:nth-child(25) {
    @include theme-aware('background-color', 'neutrals-40');
  }

  .qr-code div:nth-child(2),
  .qr-code div:nth-child(4),
  .qr-code div:nth-child(22),
  .qr-code div:nth-child(24) {
    animation-delay: 0.3s;
  }

  .qr-code div:nth-child(3),
  .qr-code div:nth-child(23) {
    animation-delay: 0.6s;
  }

  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
  }

  .typography {
    margin-top: 20px;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.small-styled-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    @include theme-aware('color', 'secondary-120');
  }
}
