@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.styled-tabs {
  margin: 5px 0;
  width: 100%;
  padding: 3px;
  border-radius: 10px;
  @include theme-aware('background-color', 'neutrals-100');
  @include theme-aware('box-shadow', 'primary-box-shadow');
  @include theme-aware('background-color', 'neutrals-100');
  .styled-tabs-inside {
    position: relative;
    display: flex;
    gap: 10px;
    padding: 2px;
    width: 100%;
    &.horizontal {
      flex-direction: row;
      overflow-x: auto;
      flex-shrink: 0;
    }

    &.vertical {
      flex-direction: column;
      height: 100%;
      .tab-btn {
        justify-content: flex-start;
      }
    }
  }
  .tab-indicator {
    position: absolute;
    border-radius: 8px;
    z-index: 1;
    @include theme-aware('color', 'primary-80');
    @include theme-aware('background-color', 'primary-140');
    transition: left 0.2s ease, width 0.2s ease, top 0.2s ease, height 0.2s ease;
    height: calc(100% - 4px);
    &.horizontal {
      bottom: 2px;
      height: calc(100% - 4px);
    }

    &.vertical {
      right: 2px;
      width: calc(100% - 4px);
      height: 32px;
    }
  }

  .tab-btn {
    min-width: 100px;
    font-size: 14px;
    padding: 5px 10px;
    text-decoration: none;
    border: none;
    @include theme-aware('color', 'neutrals-30');
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    gap: 5px;
    border-radius: 8px;
    min-height: 32px;
    flex-shrink: 0;
    justify-content: center;

    &:hover:not(:disabled) {
      @include theme-aware('color', 'primary-80');
      @include theme-aware('background-color', 'primary-140');
      transition: color 0.2s, background-color 0.2s ease;
    }

    &.selected:not(:disabled) {
      @include theme-aware('color', 'primary-80');
      transition: color 0.2s;
    }

    &:focus-visible:not(:disabled) {
      @include theme-aware('box-shadow', 'outline-box-shadow');
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
