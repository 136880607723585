@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.nav-link {
  text-decoration: none;
  border: none;
  @include theme-aware('color', 'neutrals-30');
  font-size: 15px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 5px;

  .nav-link-name {
    height: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 7px 25px;
    gap: 15px;
    font-family: 'Roboto';
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    // width: 24px;
    height: 24px;
  }

  &:visited {
    @include theme-aware('color', 'neutrals-30');
  }

  &:hover:not(.disabled) {
    @include theme-aware('background-color', 'primary-130');
    @include theme-aware('color', 'neutrals-10');
    transition: color 0.2s, background-color 0.2s;
    border-radius: 8px;
  }

  &.active {
    @include theme-aware('background-color', 'primary-140');
    @include theme-aware('color', 'primary-70');
    transition: color 0.2s, background-color 0.2s;
    border-radius: 8px;
    font-weight: 500;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus-visible {
    .nav-link-name {
      @include theme-aware('box-shadow', 'outline-box-shadow');
    }
  }
}

@media only screen and (max-width: 768px) {
  .nav-link {
    .nav-link-name {
      padding: 7px;
    }
  }
}
