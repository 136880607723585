@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.primary-btn {
  text-decoration: none;
  @include theme-aware('background-color', 'primary-100');
  padding: 6px 25px;
  font-size: 14px;
  @include theme-aware('color', 'neutrals-10');
  min-width: 120px;
  max-width: 340px;
  height: 38px;
  flex-shrink: 0;
  @include theme-aware('box-shadow', 'secondary-box-shadow');

  border-radius: 8px;
  &:hover:not(:disabled) {
    @include theme-aware('background-color', 'primary-130');
    @include theme-aware('color', 'primary-80');
    transition: all 200ms;
  }

  &:focus-visible {
    @include theme-aware('box-shadow', 'outline-box-shadow');
  }

  &:disabled {
    opacity: 0.3;
    @include theme-aware('background-color', 'primary-110');
    cursor: not-allowed;
  }
}
