@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.page-container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 12px 5px 7px;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .page-container {
    margin-top: 50px;
    padding-bottom: 30px;
    height: 100%;
    padding: 5px 10px;
  }
}
