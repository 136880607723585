@import './styles/mixins.scss';
@import './styles/themes.scss';
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.app {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.app-page-container {
  width: 100%;
  height: 100%;
  @include theme-aware('background-color', 'neutrals-90');
  @include theme-aware('color', 'neutrals-30');
  display: flex;
  flex-direction: row;
  gap: 10px;
  .app-routing-container {
    padding: 0;
    margin: 0;
  }
}

* {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: 'Roboto';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

a:hover,
a:active,
a:visited {
  @include theme-aware('color', 'neutrals-10');
}

/* width */
::-webkit-scrollbar {
  height: 3px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  @include theme-aware('background', 'semi-transparent');
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @include theme-aware('background', 'primary-120');
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @include theme-aware('background', 'primary-90');
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0px;
    -webkit-appearance: none;
  }
}

@media only screen and (max-width: 1024px) {
  .app-page-container {
    .app-routing-container {
      padding: 0;
      margin: 0;
    }
  }
}
