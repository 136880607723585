@import '../../styles/mixins.scss';
@import '../../styles/themes.scss';

.error-boundary-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .error-boundary {
    width: 650px;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    @include theme-aware('color', 'error-color');
    gap: 50px;
    text-align: center;
    svg {
      width: 120px;
      height: 120px;
    }
    .typography {
      text-align: center;
    }
    .primary-btn {
      width: 340px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .error-boundary-container {
    .error-boundary {
      padding: 20px 5px;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}
